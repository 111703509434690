<template>
  <div class="content-container home-container">
    <section class="intro">
      <article class="intro-content">
          <span class="intro-subtitle">Vítáme vás v Apartmánu</span>
          <h1 class="intro-title">Jupiter Wellness</h1>
          <p class="desc">Hvězdy jsou Vaši lásce nakloněny a planety ještě víc. Třeba takový Jupiter pro vás stvořil dokonce luxusní chatu pro dva.</p>
          <router-link class="button" to="galerie" v-on:click.native="closeNavigation">Prohlédnout</router-link>
      </article>
    </section>
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {
  
  },
  metaInfo: {
      title: 'Úvod',
      titleTemplate: '%s | Jupiter Wellness',
      meta: [
      {
        'property': 'og:title',
        'content': 'Úvod',
        'template': '%s | Jupiter Wellness',
        'vmid': 'og:title'
      },
      {
        'property': 'og:url',
        'content': 'https://jupiterwellness.cz/',
        'vmid': 'og:url'
      },
    ]
    },
  data: function () {
    return {

    };
  },
  mounted() {

  },
  methods: {
  },
  computed: {

  }
};
</script>

<style lang="scss">
@import "@/scss/main.scss";

.home-container {
  padding-top: 0;
}

.intro {
  background: #fff url('../assets/intro-bg.jpg') center/cover no-repeat;
  width: 100%;
  min-height: 100vh;
  text-align: center;
  //padding: rem(155) 0 0 0;
  align-items: center;
  display: flex;
  z-index: 1;

  @include breakpoint(desktop) {
      height: calc(100vh - #{rem(56)});
  }

  &::before {
    //content: '';
    //width: 100%;
    //height: 100%;
    //position: absolute;
    //top: 0;
    //left: 0;
    //background-color: rgba(33, 33, 35, 0.5);
    //z-index: -1;
  }

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(#212123, 0.5);
    z-index: -1;
  }

  .intro-title {
    margin: 0 auto rem(16) auto;
    z-index: 100;
  }

  .desc {
    color: #fff;
    z-index: 200;
    width: 100%;
    padding: 0 0 rem(16) 0;
    line-height: 1.25;
    font-size: rem(16);
  }

  .intro-content {
      width: percent(400, 480);
      margin: 0 auto;
      padding: rem(120) 0;
      max-width: rem(640);

    @include breakpoint(tablet) {
      width: percent(640, 940);
    }

    @include breakpoint(desktop) {
        padding: 0;
      width: percent(640, 940);
    }
  }

  .button {
      margin-top: rem(24);
    width: percent(266, 320);

    @include breakpoint(tablet) {
      width: rem(200);
    }
  }
}

</style>