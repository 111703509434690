<template>
  <div id="app" class="app-container">
    <TheHeader/>
    <router-view/>
    <cookie-law button-text="V pořádku" message="Tento web používá k poskytování služeb a analýze návštěvnosti soubory cookie. Používáním tohoto webu s tím souhlasíte."></cookie-law>
  </div>
</template>

<script>
  import TheHeader from "@/components/TheHeader";
  import CookieLaw from 'vue-cookie-law';

  export default {
    name: 'App',
    components: {
      TheHeader,
      CookieLaw,
    },
    mounted() {
    },
    methods: {

    },
    created() {

    },
    beforeDestroy() {
    }
  }
</script>

<style lang="scss">
    @import '@/scss/base/_normalize.scss';
    @import '@/scss/base/_base.scss';
    @import '@/scss/main.scss';

    .app-container {
      @include breakpoint(desktop) {
        display: flex;
      }
    }

    .content-container {
      padding-top: rem(92);

      @include breakpoint(desktop) {
        width: calc(100% - #{rem(280)});
        display: flex;
        margin: 0 0 0 rem(280);
        order: 2;
        padding-top: rem(66);
        padding-bottom: 0;
        flex-direction: column;
        overflow-y: auto;
      }

      @include breakpoint(desktop-wide) {
        margin: 0 0 0 rem(320);
        width: calc(100% - #{rem(320)});
      }
    }

    .icon {
      width: 1rem;
      height: 1rem;
      fill: #212123;
      transition: fill .2s ease-in-out;
      display: inline-block;
    }

    .Cookie {
      z-index: $z-index-7;
    }

    .Cookie__button {
      background: #212123 !important;
    }
</style>
